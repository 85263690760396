<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">manage claims</div>
      </v-row>
      <v-row class="">
        <div class="">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquid enim
          eius tempore natus! Neque dolore explicabo aliquid inventore
          consequuntur vitae illum ratione doloribus quo, cum in, soluta
          perferendis laboriosam qui.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          label="Search"
          outlined
          dense
          v-model="search"
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <v-data-table
        class="text-capitalize elevation-10"
        :search="search"
        :items="items"
        :headers="headers"
        single-expand
        show-expand
        item-key="member"
      >
        <template v-slot:[`item.claims`]="{ item }">
          {{ item.claims.length }}
        </template>
        <template v-slot:[`item.actions`]="{}">
          <v-btn
            outlined
            color="primary"
            rounded
            small
            @click="approveDialog = true"
          >
            approve/deny
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ item }">
          <v-list two-line>
            <v-list-item v-for="claim in item.claims" :key="claim.name">
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ claim.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  KES {{ claim.amount }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon color="primary" @click="receiptDialog = true">
                  <v-icon> mdi-file-pdf-box </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider inset></v-divider>
          </v-list>
        </template>
      </v-data-table>

      <v-dialog v-model="receiptDialog">
        <pdf-viewer />
      </v-dialog>

      <v-dialog v-model="approveDialog" width="auto">
        <v-card width="500">
          <v-card-title>
            <p class="text-uppercase mx-auto">approve/deny claim</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            <v-form>
              <v-autocomplete
                label="Status"
                outlined
                :items="['Approved', 'Denied']"
                dense
                v-model="markStatus"
              ></v-autocomplete>

              <v-textarea
                label="Reason"
                outlined
                dense
                v-model="reason"
              ></v-textarea>

              <v-btn block color="primary">submit</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import PdfViewer from "../components/PdfViewer.vue";
export default {
  components: { PdfViewer },
  data() {
    return {
      receiptDialog: false,
      approveDialog: false,
      markStatus: "",
      reason: "",
      search: "",
      headers: [
        {
          text: "date",
          value: "date",
          class: "text-uppercase black--text",
        },
        {
          text: "member",
          value: "member",
          class: "text-uppercase black--text",
        },
        {
          text: "claim types",
          value: "claims",
          class: "text-uppercase black--text",
        },
        {
          text: "actions",
          value: "actions",
          class: "text-uppercase black--text",
        },
      ],
      items: [
        {
          date: "2nd February 2022",
          member: "david kimani",
          claims: [
            {
              name: "accomodation",
              amount: 25020,
              document: "",
            },
            {
              name: "meals",
              amount: 2420,
              document: "",
            },
            {
              name: "medical",
              amount: 2150,
              document: "",
            },
            {
              name: "mileage",
              amount: 5420,
              document: "",
            },
          ],
        },
        {
          date: "2nd February 2022",
          member: "samuel kimani",
          claims: [
            {
              name: "accomodation",
              amount: 25020,
              document: "",
            },
            {
              name: "meals",
              amount: 2420,
              document: "",
            },
            {
              name: "medical",
              amount: 2150,
              document: "",
            },
            {
              name: "mileage",
              amount: 5420,
              document: "",
            },
          ],
        },
        {
          date: "2nd February 2022",
          member: "amos kimani",
          claims: [
            {
              name: "accomodation",
              amount: 25020,
              document: "",
            },
            {
              name: "meals",
              amount: 2420,
              document: "",
            },
            {
              name: "medical",
              amount: 2150,
              document: "",
            },
            {
              name: "mileage",
              amount: 5420,
              document: "",
            },
          ],
        },
        {
          date: "2nd February 2022",
          member: "john kimani",
          claims: [
            {
              name: "accomodation",
              amount: 25020,
              document: "",
            },
            {
              name: "meals",
              amount: 2420,
              document: "",
            },
            {
              name: "medical",
              amount: 2150,
              document: "",
            },
            {
              name: "mileage",
              amount: 5420,
              document: "",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style></style>
